import React, { useState } from 'react';
import './Style.css'; // Import your CSS for styling

const Tabs = ({ children, fontSize = '14px', borderBottom = '2px solid blue', styleObject, activeColor = 'black' }) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabHeaders = React.Children.toArray(children).filter(child => child.type === TabHeader);
  const tabContents = React.Children.toArray(children).filter(child => child.type === TabContent);
  const tabHeaderRightSides = React.Children.toArray(children).filter(child => child.type === 'tabHeaderRightSide');

  return (
    <div className="tabs">
      <div className="tabs__header">
        <div className='tab_list'>
          {tabHeaders.map((header, index) => (
            <button
              key={index}
              title={header.props.children}
              className={`tabs__button ${index === activeTab ? 'tabs__button--active' : ''}`}
              onClick={() => {
                setActiveTab(index);
                // Check if onClick exists and is a function before calling it
                if (typeof header.props.onClick === 'function') {
                  header.props.onClick();
                }
              }}
              style={{
                ...styleObject,
                borderBottom: index === activeTab ? styleObject['borderBottom'] : 'none',
                color: index === activeTab ? activeColor : '',
              }}
            >
              {header.props.image && typeof header.props.image === 'string' && (
                <img
                  src={header.props.image}
                  alt=""
                  style={{ width: styleObject['imageWidth'], marginRight: '8px' }}
                />
              )}
              {header.props.children}
            </button>
          ))}
        </div>
        <div>
          {tabHeaderRightSides.map((header, index) => (
            <div key={index} style={{ marginLeft: '10px',marginBottom:'10px' }}>
              {header.props.children}
            </div>
          ))}
        </div>
      </div>
      <div className="tabs__content">
        {tabContents[0]?.props?.children}
      </div>
    </div>
  );
};

// TabHeader component for defining the tab headers
const TabHeader = ({ children, image, onClick }) => (
  <div onClick={onClick}>
    {children}
  </div>
);

// TabContent component for defining the tab contents
const TabContent = ({ children }) => children;

const tabHeaderRightSide = ({ children }) => children;

export { Tabs, TabHeader, TabContent, tabHeaderRightSide };
