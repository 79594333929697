import React from 'react';
import user3 from '../../../assets/images/user3.png'; // Fallback image
import './Style.css';

const ImageGallery = ({ images = [], shape = 'square', disabled = false }) => {
  // Ensure images is an array
  if (!Array.isArray(images)) {
    console.error('Expected images to be an array, but got:', images);
    images = []; // Fallback to an empty array if not an array
  }

  // Limit visible images to the first two if shape is 'circle'
  const visibleImages = shape === 'circle' ? images.slice(0, 2) : images;
  const remainingCount = shape === 'circle' ? Math.max(0, images.length - 2) : 0;

  const imageStyle = {
    width: '26px',
    height: '26px',
    objectFit: 'cover',
    borderRadius: shape === 'circle' ? '50%' : '0',
    marginRight: '10px',
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? 'none' : 'auto',
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', opacity: disabled ? 0.5 : 1 }}>
      {visibleImages.map((image, index) => (
        <div
          key={index}
          className={`containerStyle ${disabled ? 'containerStyle--disabled' : ''}`}
        >
          <img
            src={image || user3} // Use the image from the array or fallback to user3
            alt={`Image ${index + 1}`}
            style={imageStyle}
            title={`Image ${index + 1}`} // Adjusted to use the index for the title
          />
        </div>
      ))}
      {shape === 'circle' && remainingCount > 0 && (
        <div
          className={`containerStyle ${disabled ? 'containerStyle--disabled' : ''}`}
          style={{ ...imageStyle, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <span>+{remainingCount}</span>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
