import React from 'react';
import './Sidebar.css';
import Arrow from '../../../assets/images/svg/Left_arrow.svg';
import { useSidebar } from "../../context/SidebarContext/SidebarContext";

const Sidebar = () => {
    const { isOpen, title, component, closeSidebar,backgroundColor } = useSidebar();

    return (

            <div className={`sidebar-right style-scroll ${isOpen ? 'open' : ''}`}
             style={{ backgroundColor }}>
                <div style={{ 
                    padding: "10px", 
                    zIndex: "1111", 
                    display: "flex", 
                    gap: "10px", 
                    borderBottom: "1px solid rgba(237, 238, 244, 1)" 
                }}>
                    <div className='sidebar-arrow'>
                        <img src={Arrow} alt="Arrow" onClick={closeSidebar} />
                    </div>
                    <div>
                        {title}
                    </div>
                </div>
                <div className="sidebar-content">
                    {component}
                </div>
            </div>
    );
};

export default Sidebar;