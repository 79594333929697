import React, { useState, useRef, useEffect } from 'react';
import './CommentView.css';
import ImageGallery from '../../Atom/Image/Image';
import option from "../../../assets/images/svg/option.svg";
import Edit from "../../../assets/images/svg/Edit.svg";
import Delete from "../../../assets/images/svg/Delete.svg";
import Dropdown from '../../Atom/CustomDropDown/CustomDropDown';

const CommentView = ({ comments, onDelete, onEdit }) => {
    const [showDropdown, setShowDropdown] = useState(null); // Track dropdown visibility for each comment
    // const inputRef = useRef(); // Ref for the option icon image
    const [isChildClosedFromParent, setIsChildClosedFromParent] = useState(false);

    const handleCloseChildFromParent = () => {
      setIsChildClosedFromParent(true);
    };
    // Handle dropdown visibility toggle
    const handleToggleDropdown = (index) => {
        setShowDropdown((prevIndex) => (prevIndex === index ? null : index));
        handleCloseChildFromParent();
    };
  useEffect(() => {
    if (isChildClosedFromParent) {
        setIsChildClosedFromParent(false);
    }
  })
    return (
        <div className="comment-view style-scroll">
            {/* COMMENTS */}
            {comments.map((comment, index) => (
                <div key={index} className="comment1" >
                    <ImageGallery
                        images={[comment.image]}
                        shape="circle"
                        className="comment-image"
                    />

                    <div className="comment-content">
                        <div className="comment-header" >
                            <div className='comment-header-left'>
                            <span className="comment-name">
                                {comment.name || "User Name"}
                            </span>
                            <span className="comment-time">
                                {comment.time || ""}
                            </span>

                            </div>

                            {/* Only show dropdown if the comment is not deleted */}
                            {!comment.isDeleted && (
                                <div className="dropdown-container" >
                                    
                                   
                                    {/* {showDropdown  && ( */}
                                    <Dropdown
                                        close={isChildClosedFromParent}
                                        trigger={
                                            <img
                                                src={option}
                                                alt="options"
                                                className="option-icon"
                                            />
                                        }
                                    >
                                          <div className="dropdown-suggestions"
                                          >
                                            <div
                                                className="dropdown-item"
                                                onClick={() => {
                                                    onEdit(index);
                                                    setIsChildClosedFromParent(true)
                                                }}
                                                

                                            >
                                                <div><img src={Edit} alt="Edit" /></div>
                                                <div>Edit</div>
                                            </div>
                                            <div
                                                className="dropdown-item"
                                                onClick={() => onDelete(index)}
                                            >
                                                <div><img src={Delete} alt="Delete" /></div>
                                                <div>Delete</div>
                                            </div>
                                        </div>
                                        </Dropdown>
                                    {/* )} */}
                                </div>
                            )}
                        </div>

                        <div className="comment-message">
                            {comment.isDeleted ? (
                                <span className="deleted-message">This message is deleted</span>
                            ) : (
                                comment.message
                            )}
                            {(comment.edited && !comment?.isDeleted) && (
                                <span className="edited-indicator"> (edited)</span>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CommentView;