import React, { useEffect, useState } from "react";
import DraggableSection from "./DraggableSection"; // Adjust path as needed
import { addTask, updateTask } from "../../../store/Task/index"; // Adjust path as needed
import { useDispatch, useSelector } from "react-redux";
import { addTagToSection, removeTagFromSection, updateSectionTags } from '../../../store/Section/index';
import useCustomComposable from "../../utils/composables";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
const SectionView = ({ sections, setSections, setTasks }) => {
    let Tagsections = JSON.parse(localStorage.getItem('Sections'))
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const { makeUniqueId } = useCustomComposable();
    const tasks = useSelector((state) => state.tasks.tasks);
    const [taskValue, setTaskValue] = useState('');
    // Handle section input changes
    const handleInputChange = (sectionId, event) => {
        const newSections = sections.map((section) =>
            section.id === sectionId ? { ...section, value: event.target.value } : section
        );
        setSections(newSections);
    };

    // Handle task input changes
    const handleTaskChange = (sectionId, event) => {
        const newSections = sections.map((section) =>
            section.id === sectionId ? { ...section, taskValue: event.target.value } : section
        );
        setSections(newSections);
    };

    // Handle changes in task rows
    const handleTaskRowChange = (taskId, field, value) => {
        dispatch(updateTask({ taskId, field, value }));
    };

    // Add a task to a section

    const handleCreateTask = (taskData) => {
        const nonSpaceCharCount = taskData.taskName.replace(/\s/g, "").length;

        // Validation for at least 3 non-space characters
        if (nonSpaceCharCount < 3) {
            toast.error("Task name must contain at least 3 non-space characters.");
            return;
        }

        // Get section tags
        const sectionTags = Tagsections
            .filter((e) => e.id === taskData.sectionId)
            .flatMap((e) => e.tags);

        const newTask = {
            id: makeUniqueId(),
            sectionId: taskData.sectionId,
            value: taskData.taskName.trim(),
            status: 0,
            dueDate: "",
            organisation: "",
            assignee: "",
            comments: false,
            tags: [...(taskData.tags || []), ...sectionTags], // Combine selected tags and section tags
        };

        // Dispatch to Redux
        dispatch(addTask(newTask));

        // Clear task input
        const newSections = sections.map((section) =>
            section.id === taskData.sectionId ? { ...section, taskValue: "" } : section
        );
        setSections(newSections);

        toast.success('Task created successfully!');
    };

    // Reorder sections
    const moveSection = (fromSectionId, toSectionId) => {
        const fromIndex = sections.findIndex((section) => section.id === fromSectionId);
        const toIndex = sections.findIndex((section) => section.id === toSectionId);

        if (fromIndex >= 0 && toIndex >= 0) {
            const newSections = [...sections];
            const [movedSection] = newSections.splice(fromIndex, 1);
            newSections.splice(toIndex, 0, movedSection);
            setSections(newSections);
        }
    };
    
    const params = searchParams.get("sectionId");

    return (
 <div>
            {params === "all_tasks" ? (
                <DraggableSection
                    key="all_tasks"
                    id="all_tasks"
                    sectionId="all_tasks"
                    index="all_tasks"
                    moveSection={moveSection}
                    item={{}} 
                    inputValue="" 
                    handleInputChange={(event) => handleInputChange("all_tasks", event)}
                    taskValue="" // Add relevant logic for all_tasks
                    handleTaskChange={(event) => handleTaskChange("all_tasks", event)}
                    onCreateTask={handleCreateTask} // Pass the new handler
                    handleTaskRowChange={(taskId, field, value) =>
                        handleTaskRowChange(taskId, field, value)
                    }
                    tags={[]} // Add any default or relevant tags for all_tasks
                    removeTag={(tag) => dispatch(updateSectionTags({ sectionId: "all_tasks", tag }))}
                    showMoreButton={false} // Set to true for all_tasks
                />
            ) : (
                sections?.map((section) => (
                    <DraggableSection
                        key={section.id}
                        id={section.id}
                        sectionId={section.id}
                        index={section.id}
                        moveSection={moveSection}
                        item={section}
                        inputValue={section.value}
                        handleInputChange={(event) => handleInputChange(section.id, event)}
                        taskValue={section.taskValue || ""}
                        handleTaskChange={(event) => handleTaskChange(section.id, event)}
                        onCreateTask={handleCreateTask} // Pass the new handler
                        handleTaskRowChange={(taskId, field, value) =>
                            handleTaskRowChange(taskId, field, value)
                        }
                        tags={Tagsections
                            .filter((e) => e.id === section.id)
                            .flatMap((e) => e.tags)}
                        removeTag={(tag) => dispatch(updateSectionTags({ sectionId: section.id, tag }))}
                        showMoreButton={params === null || params === ""} 
                    />
                ))
            )}
        </div>
    );
};

export default SectionView;
