import React from 'react';
// import './BellIcon.css'; 
import ImageGallery from '../Image/Image';
import Bell from "../../../assets/images/svg/bell.svg"
const BellIcon = () => {
    return (
        <div className="bell-icon">
           <ImageGallery images={[Bell]} shape="circle" />
        </div>
    );
};

export default BellIcon;