
import React, { useState, useEffect, useRef } from 'react';
import './Comments.css';
import ImageGallery from '../../Atom/Image/Image';
import UserProfile from "../../../assets/images/User_profile.png";
import Up_down_arrow from "../../../assets/images/svg/Up_down_arrow.svg";
import TextArea from '../../Atom/TextArea/TextArea';
import BellIcon from '../../Atom/Bell/bell';
import CommentView from '../CommentView/CommentView';
import { getComments, addComment, deleteComment } from './helper';
import Button from '../../Atom/Button/Button';

const Comments = ({ taskId }) => {
    const [comments, setComments] = useState([]); // Default to an empty array
    const [newComment, setNewComment] = useState('');
    const [editingIndex, setEditingIndex] = useState(null); // Track index of the comment being edited
    const [isRecentFirst, setIsRecentFirst] = useState(true); // Manage order of comments (newest first)
    const textAreaRef = useRef(null);

    useEffect(() => {
        const storedComments = getComments(taskId);
        setComments(storedComments ? Object.values(storedComments) : []); // Ensure storedComments is an array
        if (textAreaRef.current) textAreaRef.current.focus();
    }, [taskId]);

    const formatTime = (timeString) => {
        const date = new Date(timeString);
        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);

        if (diffInSeconds < 60) return "Just now";
        if (diffInMinutes < 60) return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
        if (diffInHours < 24) return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
        return diffInDays === 1 ? "Yesterday" : `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    };

    const handleCommentChange = (e) => {
        setNewComment(e.target.value);
    };

    const handleCommentSubmit = (e) => {
        if (e.key === 'Enter' && newComment.trim()) {
            e.preventDefault();

            if (editingIndex !== null) {
                // Update the edited comment
                const updatedComments = [...comments];
                updatedComments[editingIndex] = {
                    ...updatedComments[editingIndex],
                    message: newComment,
                    edited: true, // Add edited flag
                };

                setComments(updatedComments);
                localStorage.setItem(
                    'comments',
                    JSON.stringify({
                        [taskId]: updatedComments.reduce((acc, comment, idx) => {
                            acc[idx] = comment;
                            return acc;
                        }, {}), // Save comments in localStorage
                    })
                );
                setEditingIndex(null);
            } else {
                // Add a new comment
                const commentData = {
                    message: newComment,
                    time: new Date().toISOString(),
                    image: UserProfile,
                    name: "User Name",
                    edited: false,
                    taskId,
                };
                addComment(taskId, commentData);
                setComments((prevComments) => [...prevComments, commentData]);
            }
            setNewComment('');
        }
    };

    const handleEditComment = (commentIndex) => {
        // Get the original index before sorting
        const originalIndex = sortedComments.findIndex(comment => comment === comments[commentIndex]);
        setEditingIndex(originalIndex);
        setNewComment(comments[originalIndex].message);
        if (textAreaRef.current) textAreaRef.current.focus();
    };

    const handleDeleteComment = (commentIndex) => {
        // Get the original index before sorting
        const originalIndex = sortedComments.findIndex(comment => comment === comments[commentIndex]);
        deleteComment(taskId, originalIndex);
        const updatedComments = getComments(taskId);
        setComments(updatedComments ? Object.values(updatedComments) : []);
    };

    // Toggle order between recent first and last first
    const toggleCommentOrder = () => {
        setIsRecentFirst(!isRecentFirst);
    };

    // Sort comments based on isRecentFirst state
    const sortedComments = [...comments].sort((a, b) => {
        const timeA = new Date(a.time);
        const timeB = new Date(b.time);
        return isRecentFirst ? timeB - timeA : timeA - timeB;
    });

    return (
        <div className="comments-section">
            <div className="comments-header">
                <div>Comments</div>
                <div className="toggle-order">
                    <Button
                        onClick={toggleCommentOrder}
                        type="default"
                        text={isRecentFirst ? 'Oldest First' : 'Newest First'}
                    />
                    <img
                        style={{ transform: isRecentFirst ? 'rotate(0deg)' : 'rotate(180deg)' }}
                        src={Up_down_arrow}
                        alt="Sort Order"
                    />

                </div>
            </div>
            <div className="comments-container">
                <div className="comment-view-container">
                    <CommentView
                        comments={sortedComments.map((comment) => ({
                            ...comment,
                            time: formatTime(comment.time),
                        }))}
                        onDelete={handleDeleteComment}
                        onEdit={handleEditComment}
                    />
                </div>
                <div className="comment">
                    <div className="profile-image">
                        <ImageGallery images={[UserProfile]} shape="circle" />
                    </div>
                    <div>
                        <TextArea
                            placeholder="Add a comment"
                            rows={5}
                            value={newComment}
                            onChange={handleCommentChange}
                            onKeyDown={handleCommentSubmit}
                            ref={textAreaRef}
                        />
                    </div>
                </div>
                <div className="notification-icon">
                    <BellIcon />
                </div>
            </div>
        </div>
    );
};

export default Comments;
