import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import ReactDOM from "react-dom";
import { useCustomComposable } from "../../utils/composables/index";
import './style.css';

let currentlyOpenPicker = null;

const Dropdown = forwardRef(({ disabled = false, trigger, children, close, targetRef }, ref) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { makeUniqueId } = useCustomComposable();
  const [uid] = useState(makeUniqueId());

  useImperativeHandle(ref, () => {
    return {
      openDropDown: () => {
        handleTriggerClick()
      },
      closeDropDown: () => setIsOpen(false)
    };
  })

  useEffect(() => {
    if (!isOpen) currentlyOpenPicker = null;
    return () => {
      if (currentlyOpenPicker && currentlyOpenPicker.uid === uid) {
        currentlyOpenPicker = null;
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (close) {
      setIsOpen(false);
    }
  }, [close]);

  const handleTriggerClick = () => {
    if (disabled) return;

    if (currentlyOpenPicker && currentlyOpenPicker.uid !== uid) {
      currentlyOpenPicker.setIsOpen(false);
    }

    setIsOpen(true);
    currentlyOpenPicker = { uid, setIsOpen };

    if (dropdownRef.current || targetRef.current) {
      setTimeout(() => {
        const target = targetRef?.current;
        const element = target || document.getElementById(uid);
        const childNode = document.getElementById(`dd_${uid}`);

        if (!element || !childNode) {
          console.warn(`Element or childNode not found for uid: ${uid}`);
          return;
        }

        const { top, left, height: ddHeight } = element.getBoundingClientRect();
        
        const { height, width } = childNode.getBoundingClientRect();

        if (document.documentElement.clientWidth < (left + width + 25)) {
          const offset = document.documentElement.clientWidth - (left + width + 15);
          childNode.style.left = left + offset + "px";
        } else {
          childNode.style.left = left + "px";
        }

        if (document.documentElement.clientHeight < (top + height + 25)) {
          const offset = document.documentElement.clientHeight - (top + height + 15);
          childNode.style.top = top + offset + "px";
        } else {
          childNode.style.top = top + ddHeight + "px";
        }
      });
    }
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <div className={`dropdown-wrapper ${disabled ? 'dropdown-wrapper--disabled' : ''}`} style={{ display: "inline-block" }}>
      <div
        onClick={handleTriggerClick}
        ref={dropdownRef}
        id={`${uid}`}
        style={{ cursor: disabled ? "not-allowed" : "pointer", display: "inline-block" }}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {trigger}
      </div>

      {isOpen && !disabled && ReactDOM.createPortal(
        <>
          {/* Overlay to cover the rest of the screen */}
          <div
            className="dropdown-overlay"
            onClick={closeDropdown}
          ></div>
          <div
            className="dropdown-content"
            id={`dd_${uid}`}
            style={{
              position: 'absolute',
            }}
          >
            {React.isValidElement(children)
              ? React.cloneElement(children, { closeDropdown })
              : React.Children.map(children, child =>
                  React.isValidElement(child) ? React.cloneElement(child, { closeDropdown }) : child
                )}
          </div>
        </>,
        document.body
      )}
    </div>
  );
});

export default Dropdown;
