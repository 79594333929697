    export const getComments = (taskId) => {
        const comments = localStorage.getItem('comments');
        const parsedComments = comments ? JSON.parse(comments) : {};
        return parsedComments[taskId] || {}; // Return comments for the specific taskId as an object
    };

    export const addComment = (taskId, comment) => {
        const comments = getComments(taskId);
        const commentIndex = Object.keys(comments).length; // Get the next index for the new comment
        const updatedComments = {
            ...JSON.parse(localStorage.getItem('comments') || '{}'),
            [taskId]: {
                ...comments,
                [commentIndex]: { ...comment, taskId } // Ensure taskId is included in the comment
            }
        };
        localStorage.setItem('comments', JSON.stringify(updatedComments)); // Store updated comments
    };
    export const deleteComment = (taskId, commentIndex) => {
        const comments = getComments(taskId);
        if (comments[commentIndex]) {
            // Set isDeleted flag to true
            comments[commentIndex] = { 
                ...comments[commentIndex], 
                isDeleted: true 
            };
    
            // Update the localStorage with the modified comments
            const updatedComments = {
                ...JSON.parse(localStorage.getItem('comments') || '{}'),
                [taskId]: comments
            };
            localStorage.setItem('comments', JSON.stringify(updatedComments));
        }
    };
    
    
    export const editComment = (taskId, commentIndex, updatedMessage) => {
        const comments = getComments(taskId);
        if (comments[commentIndex]) {
            // Update the message and mark it as edited
            comments[commentIndex] = {
                ...comments[commentIndex],
                message: updatedMessage,
                edited: true, // Use a flag to indicate the comment was edited
                time: new Date().toISOString(), // Update the timestamp
            };
    
            // Update the localStorage with the modified comments
            const updatedComments = {
                ...JSON.parse(localStorage.getItem('comments') || '{}'),
                [taskId]: comments
            };
            localStorage.setItem('comments', JSON.stringify(updatedComments));
        }
    };
    