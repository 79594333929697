import React, { useState, useEffect } from "react";
import { Tabs, TabHeader, TabContent } from "../Atom/Tabs/Tabs";
import Section from '../../assets/images/svg/Section.svg';
import List from '../../assets/images/svg/List.svg';
import RecentTask from '../../assets/images/svg/RecentTask.svg';
import Upcoming from '../../assets/images/svg/Upcoming.svg';
import RecentComments from '../../assets/images/svg/RecentComments.svg';
import MainLayout from "../context/Mainlayout";
import Card from "../Atom/Card/Card";
import RightArrow from '../../assets/images/svg/Right_arrow.svg';
import './Tasks.css';  // Import the CSS file
import Button from "../Atom/Button/Button";
import SearchInput from "../Molecules/SearchInput/SearchInput";
import SectionView from "../Templates/SectionView/SectionView";
import data from '../../data.json'; // Import the JSON file
import TaskDetails from "../organisms/taskDetails";
import { setTask } from "../../store/Task/index"; // Adjust path as needed
import { useDispatch, useSelector } from "react-redux";
import { addSection, setSection } from "../../store/Section";
import useCustomComposable from "../utils/composables";
import Sidebar from "../Atom/SideBar/Sidebar";
import { toast } from "react-toastify";
import { useSidebar } from '../context/SidebarContext/SidebarContext'
import Tags from "../Atom/Tags/tagsV2";
import TagFilter from "../organisms/TagFilter/tagFilter";
import { useSearchParams } from "react-router-dom";

const Tasks = () => {
    const dispatch = useDispatch();
    const { makeUniqueId } = useCustomComposable();
    // const Storetags = JSON.parse(localStorage.getItem('tags'));
    const Storetags = useSelector((state) => state.tags.tags);
    const [sections, setSections] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [activeTab, setActiveTab] = useState({ id: 'all', name: 'All' });
    const [searchParams, setSearchParams] = useSearchParams();
    // const [open, setOpen] = useState(false);

    let section = useSelector((state) => state.sections.sections);
    let storeTasks = useSelector((state) => state.tasks.tasks);

    const { isOpen, sidebarContent, openSidebar, closeSidebar } = useSidebar();

    // Load tasks and sections on component mount
    useEffect(() => {
        initializeSectionsAndTasks();
    }, []);

    useEffect(() => {
        if (section) { // section is your Redux selector
            setSections(section);
        }
    }, [section]);

    // Function to initialize sections and tasks
    const initializeSectionsAndTasks = () => {
        updateLocalStorage();

        // Load tasks from localStorage
        const storedTasks = JSON.parse(localStorage.getItem('Tasks'));
        storeTasks = storedTasks ? [...storedTasks] : [];
        dispatch(setTask(storeTasks));

        // Load sections from localStorage or JSON data
        const storedSections = JSON.parse(localStorage.getItem('Sections'));
        section = storedSections ? [...storedSections] : [];
        dispatch(setSection(section))
        if (storedSections) {
            setSections(storedSections);
        } else {
            const { sections: dataSections } = data;
            setSections(dataSections);
            localStorage.setItem('Sections', JSON.stringify(dataSections));  // Store in localStorage
        }

        setTasks(storeTasks);
    };

    // Update localStorage with tasks and sections
    const updateLocalStorage = () => {
        const localStorageTasks = JSON.parse(localStorage.getItem('Tasks'));
        if (!localStorageTasks) {
            localStorage.setItem('Tasks', JSON.stringify(data.tasks));
        }

        const localStorageSections = JSON.parse(localStorage.getItem('Sections'));
        if (!localStorageSections) {
            localStorage.setItem('Sections', JSON.stringify(data.sections));
        }
    };

    // Add a tag to the section
    const addTagToSection = (sectionId, newTag) => {
        const updatedSections = sections.map(section => {
            if (section.id === sectionId) {
                return {
                    ...section,
                    tags: [...section.tags, newTag], // Add the new tag
                };
            }
            return section;
        });
        setSections(updatedSections);
        localStorage.setItem('Sections', JSON.stringify(updatedSections));  // Update localStorage
    };

    // Remove a tag from the section
    const removeTagFromSection = (sectionId, tagToRemove) => {
        const updatedSections = sections.map(section => {
            if (section.id === sectionId) {
                return {
                    ...section,
                    tags: section.tags.filter(tag => tag !== tagToRemove), // Remove the tag
                };
            }
            return section;
        });
        setSections(updatedSections);
        localStorage.setItem('Sections', JSON.stringify(updatedSections));  // Update localStorage
    };


    useEffect(() => {
        if (activeTab.id !== 'all') {
            const currentSection = sections.find(section => section.id === activeTab.id);
            if (currentSection) {
                setActiveTab(prev => ({
                    ...prev,
                    name: currentSection.value
                }));
            }
        }
    }, [sections]);



    // Filter tasks based on the active tab
    useEffect(() => {
        const filtered = tasks?.filter(task => {
            if (activeTab.id === 'all') {
                return true;
            }
            return task.sectionId === activeTab.id;
        });

        setFilteredTasks(filtered || []);
    }, [tasks, activeTab.id]); // Note: depend on activeTab.id, not the whole object

    // Toggle sidebar open/close
    const toggleSidebar = () => {
        if (!isOpen) {
            openSidebar('Tags',
                <TagFilter
                    tags={Storetags}
                />
                , '#F6F7FA'
            ); // Open with 'Tags' content
        } else {
            closeSidebar();
        }
    };

    // Handle tab changes
    const handleTabChange = (sectionId, sectionName) => {
        setActiveTab({
            id: sectionId,
            name: sectionName
        });

        const newParams = new URLSearchParams(searchParams);
        if (sectionId === "all") {
            newParams.delete("sectionId");
        } else {
            newParams.set("sectionId", sectionId);
        }
    
        setSearchParams(newParams);
    };
    
    const handleAddSection = () => {
        const newSection = {
            id: makeUniqueId(),
            value: "New Section",
        };

        // Dispatch the addSection action
        dispatch(addSection(newSection));

        // Focus on the new section's input after a short delay
        setTimeout(() => {
            const newSectionInput = document.getElementById(`section-input-${newSection.id}`);
            if (newSectionInput) {
                newSectionInput.focus();
                newSectionInput.select(); // This will select the "New Section" text
            }
        }, 100);

        toast.success("New section added successfully!");
    };

    const leftContent = () => (
        <div className="left-main" style={{ padding: "10px" }}>
            <div className="hover-button">
             {!isOpen && ( // Conditionally render the button based on 'open' state
                   <Button Pageref='openSidebar' onClick={toggleSidebar} imageSrc={RightArrow} imageAlt="Button Image" type="trasperant" />
             )}

            </div>
            <div className="topSection">
                <div className="bottomLevel">
                    <Card title="Recent Task" body={<img src={RecentTask} alt="Recent Task" />} />
                    <Card title="Upcoming" body={<img src={Upcoming} alt="Upcoming" />} />
                    <Card title="Recent Comments" body={<img src={RecentComments} alt="Recent Comments" />} />
                </div>
            </div>
            <div className="bottomSection">
                <Tabs tabs={[
                    {
                        label: "All",
                        id: 'all',
                        onClick: () => {
                            handleTabChange('all', 'All');
                        }
                    },
                    {
                        label: "All Tasks",
                        id: 'all_tasks',
                        onClick: () => {
                            handleTabChange('all_tasks', 'All Tasks');
                        }
                    },
                    ...sections?.map(section => ({
                        label: section.value,
                        id: section.id,
                        onClick: () => handleTabChange(section.id, section.value)
                    }))
                ]}
                    styleObject={{ fontSize: "18px", imageWidth: "11px" }}
                    activeColor="rgba(55, 135, 235, 1)">
                    <TabHeader key="all-tab"
                        onClick={() => handleTabChange('all', 'All')}>All</TabHeader>
                    <TabHeader
                        key="all_tasks"
                        id="all_tasks" // Ensure this matches the dynamic ones
                        onClick={() => handleTabChange('all_tasks', 'All Tasks')}
                    >
                        Tasks
                    </TabHeader>
                    {sections.map((section, index) => (
                        <TabHeader key={section.id}
                            onClick={() => handleTabChange(section.id, section.value)}>
                            {section.value}
                        </TabHeader>
                    ))}
                    <tabHeaderRightSide>
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <SearchInput placeholder={""} />
                            <div className="Divider"></div>
                            <Button
                                text="Add Section"
                                type="primary"
                                onClick={handleAddSection}  // Updated this line
                            />
                        </div>
                    </tabHeaderRightSide>
                    <TabContent>
                        {/* Show SectionView only for the active tab's data */}
                        <SectionView
                            sections={sections.filter(section =>
                                activeTab.id === 'all' || section.id === activeTab.id
                            )}
                            tasks={filteredTasks}
                            setSections={setSections}
                            setTasks={setTasks}
                        />
                    </TabContent>
                </Tabs>
            </div>
        </div>
    );

    return (
        <div className="tasks-container">
            <Tabs styleObject={{ fontSize: "14px", borderBottom: "1px solid rgba(50, 50, 50, 1)", imageWidth: "11px" }}>
                <TabHeader image={Section}>Section</TabHeader>
                <TabHeader image={List}>List</TabHeader>
                <TabContent>
                    <MainLayout sidebar={leftContent()} leftContentSize={"100%"} rightContentSize={isOpen ? "449px" : "0px"}>
                        {isOpen && (
                            <div  style={{ display: "block", height: "100%", background: "#fff" }}>
                                <Sidebar
                                    isOpen={isOpen}
                                    onClose={closeSidebar}
                                >
                                    {sidebarContent}
                                    {/* Add your content based on sidebarContent */}
                                </Sidebar>
                            </div>
                        )}
                    </MainLayout>
                </TabContent>
                <TabContent>Content 2 for Tab 2</TabContent>
            </Tabs>
        </div>
    );
};

export default Tasks;
