import React, { useState, useEffect, useRef, useCallback } from 'react';
import ClosedIcon from "../../../assets/images/svg/Close_Icon.svg";
import { filterTags } from './helper';
import Dropdown from '../CustomDropDown/CustomDropDown';
import './Style.css';
import InputBox from '../Input/InputField';

function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), timeout);
    };
}

const Tags = ({ 
    tags = [], 
    tagOptions = [], 
    disabled = false, 
    handleAddTag, 
    handleRemoveTag, 
    minVisibleTags,
    onExpandColumn,
    columnWidth = 200 
}) => {
    const [newTag, setNewTag] = useState('');
    const [filteredTags, setFilteredTags] = useState([]);
    const [showAllTags, setShowAllTags] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [visibleCount, setVisibleCount] = useState(minVisibleTags || 1);
    
    const tagContainerRef = useRef(null);
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const prevColumnWidthRef = useRef(columnWidth);

    const TAG_PADDING = 16;
    const TAG_MARGIN = 4;
    const INPUT_BUTTON = 83;
    const TAG_CLOSE_BUTTON = 20;
    const BUBBLE_WIDTH = 40;

    // Calculate visible tags based on width
    const calculateVisibleTags = useCallback(() => {
        if (!tags.length || !columnWidth) return 1;

        const temp = document.createElement('span');
        temp.style.visibility = 'hidden';
        temp.style.position = 'absolute';
        document.body.appendChild(temp);

        let availableWidth = columnWidth - BUBBLE_WIDTH;
        let totalWidth = 0;
        let count = 0;
        for (let i = 0; i < tags.length; i++) {
            temp.textContent = tags[i].value;
            const tagWidth = temp.offsetWidth + TAG_PADDING + TAG_MARGIN;
            // const tagWidth = temp.offsetWidth + TAG_PADDING  ;
            if (totalWidth + tagWidth > availableWidth) break;

            totalWidth += tagWidth;
            count++;
        }

        document.body.removeChild(temp);
       
        const minCount = minVisibleTags !== undefined ? Number(minVisibleTags) : 1;
        return Math.max(minCount, count);
    }, [tags, columnWidth, minVisibleTags]);

    // Handle resize with width change check
    useEffect(() => {
        if (!isExpanded && prevColumnWidthRef.current !== columnWidth) {
            const count = calculateVisibleTags();
            setVisibleCount(count);
            prevColumnWidthRef.current = columnWidth;
        }
    }, [columnWidth, isExpanded, calculateVisibleTags]);

    // Handle expand/collapse
    const handleExpandTags = useCallback(() => {
        const newExpandedState = !isExpanded;
        setIsExpanded(newExpandedState);
        setShowAllTags(newExpandedState);
        
        if (newExpandedState) {
            onExpandColumn?.();
        } else {
            const count = calculateVisibleTags();
            setVisibleCount(count);
        }
    }, [isExpanded, calculateVisibleTags]);

    const debouncedFilterTags = (inputValue) => {
        const results = filterTags(inputValue, tagOptions);
        setFilteredTags(results);
    };

    useEffect(() => {
        debouncedFilterTags(newTag);
    }, [newTag]);;

    // Dropdown effect
    useEffect(() => {
        const debouncedDropdown = debounce(() => {
            if (newTag && filteredTags.length > 0 && dropdownRef.current) {
                dropdownRef.current?.openDropDown();
            } else {
                dropdownRef.current?.closeDropDown();
            }
        }, 700);

        debouncedDropdown();
        return () => clearTimeout(debouncedDropdown);
    }, [filteredTags, newTag]);

    // Calculate visible and remaining tags
    const visibleTags = showAllTags ? tags : tags?.slice(0, visibleCount);
    const remainingCount = !showAllTags ? tags.length - visibleCount : 0;

    return (
        <div 
            className={`tags ${disabled ? 'tags--disabled' : ''}`} 
            ref={tagContainerRef}
            style={{ maxWidth: columnWidth + INPUT_BUTTON }}
            // style={{ maxWidth:prevColumnWidthRef.current + INPUT_BUTTON }}
        >
            {visibleTags?.map((tag, index) => (
                <div
                    title={tag.value}
                    key={tag.id || index}
                    className={`tag ${disabled ? "tag--disabled" : ""}`}
                >
                    <span>{tag.value}</span>
                    {!disabled && (
                        <button
                            className="tag__remove-button"
                            onClick={() => handleRemoveTag(tag)}
                            aria-label="Remove tag"
                        >
                            <img src={ClosedIcon} alt="Close icon" />
                        </button>
                    )}
                </div>
            ))}

            {!showAllTags && remainingCount > 0 && (
                <div
                    className={`tag tag--remaining`}
                    onClick={handleExpandTags}
                    title={`Show all ${tags?.length} tags`}
                >
                    +{remainingCount}
                </div>
            )}

            {!disabled && (
                <div className="dropdown-suggestions">
                    <div className={'tag'} ref={inputRef}>
                        <InputBox
                            type="text"
                            value={newTag}
                            onClick={(e) => e.preventDefault()}
                            onChange={(e) => setNewTag(e.target.value)}
                            placeholder="Add Tag"
                            disabled={disabled}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleAddTag(newTag);
                                    setNewTag("");
                                }
                            }}
                        />
                        {newTag && filteredTags.length > 0 && (
                            <Dropdown targetRef={inputRef} ref={dropdownRef}>
                                <div className="dropdown-suggestions">
                                    {filteredTags?.map((tag, index) => (
                                        <div
                                            className='dropdown-item'
                                            key={index}
                                            onClick={() => {
                                                handleAddTag(tag.value);
                                                setNewTag("");
                                            }}
                                        >
                                            {tag.value}
                                        </div>
                                    ))}
                                </div>
                            </Dropdown>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Tags;





































































