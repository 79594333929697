import React, { forwardRef } from 'react';
import './Style.css';

const TextArea = forwardRef(({ value, onChange, placeholder, rows, disabled = false, onKeyDown }, ref) => {
    return (
        <textarea 
            className='textArea'
            value={value} 
            onChange={onChange} 
            placeholder={placeholder} 
            rows={rows} 
            disabled={disabled}
            onKeyDown={onKeyDown} // Attach the onKeyDown event
            ref={ref} // Attach the ref to the textarea
        />
    );
});

export default TextArea;